import classes from "./Home.module.css";
const Home = () => {
  return (
    <div className={classes.home}>
      <img className={classes["top-wave"]} src="/top-wave.svg"></img>
      <img src="/waves.svg" className={classes.waves} />
      <div className={classes.nav}>
        <img src="/logo.png" />
        <a
          href="https://tally.so/r/wMedep"
          target={"_blank"}
          className={classes.btn}
        >
          Get Started
          <img src="/arrow.svg" />
        </a>
      </div>
      <div className={classes.main}>
        <span>FOR COMPANIES HIRING INFLUENCERS FOR MARKETING</span>
        <h2>
          Find influencer marketing deals
          <br />
          agencies don’t want you to see
        </h2>

        <p>
          Over 200+ agencies compete with each other to find you the best deal.
          <br />
          We’re exposing loopholes in influencer marketing pricing to save you
          money.
        </p>
        <a
          href="https://tally.so/r/wMedep"
          target={"_blank"}
          className={classes.btn}
        >
          Get Started
          <img src="/arrow.svg" />
        </a>
        <img src="/dashboard.png" />
      </div>
    </div>
  );
};

export default Home;
